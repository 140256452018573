import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "../components/common/Loader";
import { BasePage } from "../components/Layout/BasePage";
import { StationDetailsForm } from "../components/station/StationDetailsForm";
import { useInterval } from "../hooks/useInterval";
import { useStation } from "../hooks/useStation";

export const StationDetailsPage = () => {
  const { id } = useParams();
  // const [loader, setLoader] = useState(false);
  const { loader: stationLoader, updateConnectorStates, station, loadStation } = useStation();
  const navigate = useNavigate();

  useInterval(async () => {
    updateConnectorStates();
  }, 5000);

  useEffect(() => {
    if (!id) {
      navigate("/");
    } else {
      loadStation(id).then(() => null);
    }
  }, [id]);

  return (
    <BasePage>
      {stationLoader && <Loader fullBlind />}
      {station ? <StationDetailsForm station={station} /> : ""}
    </BasePage>
  );
};
