import React from "react";

import { Box } from "@mui/material";

import europeanUnionLogoSrc from "../../common/assets/images/partners/european-union.png";
import urbanMobilityLogoSrc from "../../common/assets/images/partners/urban-mobility.png";

const images = [
  {
    src: urbanMobilityLogoSrc,
    alt: "Urban Mobility",
    width: "200px"
  },
  {
    src: europeanUnionLogoSrc,
    alt: "European Union",
    width: "200px"
  }
];

export const PartnerLogoBar = () => {
  return (
    <Box
      sx={{
        display: "flex"
      }}
    >
      {images.map(({ src, alt, width }, index) => (
        <Box
          key={index}
          sx={{
            mr: 2
          }}
        >
          <img src={src} alt={alt} width={width} />
        </Box>
      ))}
    </Box>
  );
};
