import Cookies from "universal-cookie";

class CookiesService {
  instance: Cookies;

  constructor() {
    this.instance = new Cookies();
  }

  set(key: string, value: string) {
    this.instance.set(key, value, {
      path: "/"
    });
  }

  get(key: string) {
    return this.instance.get(key);
  }

  remove(key: string) {
    this.instance.remove(key);
  }
}

const instance = new CookiesService();

export default instance;
