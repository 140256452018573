import moment from "moment";

export const getApproximateCost = (
  accumulatedEnergy: number,
  sessionStart: string,
  sessionPrice: number,
  minutePrice: number,
  kwhPrice: number,
  minimalPrice: number
) => {
  const timeNow = moment();
  const duration = moment.duration(timeNow.diff(moment(sessionStart)));
  const durationInMinutes = duration.asMinutes();
  const minuteCost = durationInMinutes * minutePrice;
  const kwhCost = kwhPrice * accumulatedEnergy;
  const cost = (Number.isNaN(minuteCost) ? 0 : minuteCost) + kwhCost + sessionPrice;

  if (cost < minimalPrice) {
    return minimalPrice;
  }

  return cost.toFixed(2);
};
