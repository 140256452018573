import { IBaseApiResponse } from "../../../common/interfaces/api.interface";
import { IConnectorSession } from "../../../common/interfaces/connector.interface";
import { IScopedStationConnector } from "../../../common/interfaces/station.interface";
import BaseAPIService from "../BaseApiService";

class CtekConnectorApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/ctek/connector`);
  }

  public async getConnectorStatesByIds(ids: string[]): Promise<IScopedStationConnector[]> {
    const res = await this.api.post<IBaseApiResponse<IScopedStationConnector[]>>(
      `/status`,
      {
        connector_ids: ids
      },
      {
        headers: {
          Authorization: ""
        }
      }
    );

    return res.data.data;
  }

  public async getConnectorSessionById(id: string): Promise<IConnectorSession> {
    const res = await this.api.get<IBaseApiResponse<IConnectorSession>>(`/session/${id}`, {
      headers: {
        Authorization: ""
      }
    });

    return res.data.data;
  }
}

const instance = new CtekConnectorApiService();

export default instance;
