import { IBaseApiResponse } from "../../../common/interfaces/api.interface";
import { ISite } from "../../../common/interfaces/site.interface";
import BaseAPIService from "../BaseApiService";

class SiteApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/site`);
  }

  public async getSiteById(id: string): Promise<ISite> {
    // return {
    //   id: "79193183-6631-4889-8d7e-f3d44788d5a1",
    //   siteId: null,
    //   name: "SimpleCharge Tērbatas",
    //   description: "Tērbatas ielas uzlādes stacija",
    //   location: {
    //     address: "Tērbatas iela -"
    //   },
    //   accessibility: 1,
    //   active: "24/7"
    // };

    const res = await this.api.get<IBaseApiResponse<ISite>>(`/site/${id}`, {
      headers: {
        Authorization: ""
      }
    });

    return res.data.data;
  }
}

const instance = new SiteApiService();

export default instance;
