import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { API_ERROR_MESSAGES } from "../common/constants/error-messages.constant";
import { STORAGE_KEYS } from "../common/constants/storage-values.constant";
import { ApiErrorCode } from "../common/interfaces/api.interface";
import { IConnectorSession, ISessionSummary, SessionStatus } from "../common/interfaces/connector.interface";
import LocalStorageService from "../services/LocalStorageService";
import { useInterval } from "./useInterval";
import { useServiceContainer } from "./useServiceContainer";

export const useConnectorSession = (id: string) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [stopLoader, setStopLoader] = useState(false);

  const [connectorSession, setConnectorSession] = useState<IConnectorSession | null>(null);

  const [sessionSummaryData, setSessionSummaryData] = useState<ISessionSummary | null>(null);

  const { sessionService } = useServiceContainer();

  const { stopInterval, resumeInterval } = useInterval(async () => {
    loadConnectorSession(id, true).then(() => null);
  }, 5000);

  const clearSession = () => {
    LocalStorageService.remove(STORAGE_KEYS.SESSION_ID);
    navigate("/");
  };

  const loadSummaryData = useCallback(async (trxId: string) => {
    try {
      const res = await sessionService.getSessionSummaryByTrxId(trxId);
      setSessionSummaryData(res);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const apiErrorHandler = (error: any) => {
    if (error.response && error.response.data.code) {
      const codesToHandle = [ApiErrorCode.SessionAborted, ApiErrorCode.TransactionInvalid];

      if (codesToHandle.includes(error.response.data.code)) {
        stopInterval();
        clearSession();

        if (API_ERROR_MESSAGES[error.response.data.code]) {
          toast.error(API_ERROR_MESSAGES[error.response.data.code]);
        }
      }
    }
  };

  const loadConnectorSession = useCallback(async (trxId: string, withoutLoader = false) => {
    if (!withoutLoader) setLoader(true);
    try {
      const stationRes = await sessionService.getConnectorSessionByTrxId(trxId);

      LocalStorageService.set(STORAGE_KEYS.SESSION_ID, trxId);

      // if ctek status is finished, then we stop interval, and load summary data
      if (stationRes.transaction.ctek_status === SessionStatus.Finished) {
        stopInterval();
        setStopLoader(false);
        await loadSummaryData(trxId);
      }

      setConnectorSession((pv) => ({
        session: stationRes.session ?? pv?.session ?? null,
        transaction: stationRes.transaction
      }));
      if (!withoutLoader) setLoader(false);
    } catch (e) {
      apiErrorHandler(e);
    }
  }, []);

  const stopSession = useCallback(async () => {
    if (!connectorSession?.session) {
      console.error("No CTEK session found");
      return;
    }

    stopInterval();

    try {
      setStopLoader(true);
      await sessionService.stopSession(id, connectorSession.session.connector_uuid);
      resumeInterval();
    } catch (e) {
      console.error("Error while stopping session", e);
      resumeInterval();
      setStopLoader(false);
    }
  }, [id, connectorSession]);

  return {
    connectorSession,
    sessionSummaryData,
    loader,
    stopLoader,
    loadConnectorSession,
    stopSession
  };
};
