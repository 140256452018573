import { IBaseApiResponse } from "../../../common/interfaces/api.interface";
import { IConnectorSession, ISessionSummary } from "../../../common/interfaces/connector.interface";
import { IPreparedSession } from "../../../common/interfaces/session.interface";
import BaseAPIService from "../BaseApiService";

class SessionApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/simplecharge/session`);
  }

  public async prepareConnectorSession(connectorId: string): Promise<IPreparedSession> {
    const res = await this.api.post<IBaseApiResponse<IPreparedSession>>(
      `/prepare`,
      {
        connector_uuid: connectorId
      },
      {
        headers: {
          Authorization: ""
        }
      }
    );

    return res.data.data;
  }

  public async stopSession(trxId: string, connectorId: string): Promise<void> {
    await this.api.post<IBaseApiResponse<any>>(
      `/stop`,
      {
        connector_uuid: connectorId,
        transaction_uuid: trxId
      },
      {
        headers: {
          Authorization: ""
        }
      }
    );
  }

  public async getConnectorSessionByTrxId(transactionId: string): Promise<IConnectorSession> {
    const res = await this.api.get<IBaseApiResponse<IConnectorSession>>(`/${transactionId}`, {
      headers: {
        Authorization: ""
      }
    });

    return res.data.data;
  }

  public async getSessionSummaryByTrxId(transactionId: string): Promise<ISessionSummary> {
    const res = await this.api.get<IBaseApiResponse<ISessionSummary>>(`/${transactionId}/summary`, {
      headers: {
        Authorization: ""
      }
    });

    return res.data.data;
  }

  public cancelTransactionById(transactionId: string, returnUrl: string): void {
    const queryParams = new URLSearchParams({
      return_url: returnUrl
    });
    window.location.replace(`${this.baseUrl}/${transactionId}/cancel?${queryParams.toString()}`);
  }
}

const instance = new SessionApiService();

export default instance;
