export interface ICtekConnectorSession {
  connector_uuid: string;
  station_name: string;
  station_id: string;
  accumulated_energy: number;
  power: number;
}

export interface IConnectorSession {
  session: ICtekConnectorSession | null;
  transaction: ITransaction;
}

export interface ISessionSummary {
  station_name: string;
  station_id: string;
  connector_uuid: string;
  accumulated_energy: number;
  session_start: string;
  session_end: string;
  amount_charged: number;
  invoice_url: string;
  kwh_price: number;
  minimal_price: number;
  minute_price: number;
  session_price: number;
  currency: string;
}

export interface ITransaction {
  ctek_status: SessionStatus;
  session_start: string; // iso
  session_price: number;
  minute_price: number;
  kwh_price: number;
  currency: string;
  minimal_price: number;
}

export enum SessionStatus {
  Preparing = 1,
  Active = 2,
  Finished = 3
}
