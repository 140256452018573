import React, { useEffect, useMemo, useState } from "react";

import { Autocomplete, Box, Button, Card, CardContent, CircularProgress, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useStationList } from "../../hooks/useStationList";

interface StationOption {
  label: string;
  value: string;
}

export const FindStationForm = () => {
  const [searchInput, setSearchInput] = useState("");
  const [selectedStationOption, setSelectedStationOption] = useState<StationOption | null>(null);

  const { loader, loadStationList, stationList } = useStationList();
  const navigate = useNavigate();

  const stationOptions = useMemo(() => {
    return stationList.map((e) => ({
      label: e.station_id,
      value: e.station_uuid
    })) as StationOption[];
  }, [stationList]);

  const handleContinueClick = () => {
    if (!selectedStationOption) return;
    navigate(`/station/${selectedStationOption.value}`);
  };

  useEffect(() => {
    loadStationList();
  }, []);

  return (
    <Card sx={{ background: "#fcfcfc" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          py: 4,
          "&:last-child": {
            paddingBottom: 4
          }
        }}
      >
        {/* <Typography variant="h4" gutterBottom> */}
        {/*  Charge without registering */}
        {/* </Typography> */}
        <Box
          sx={{
            w: "100%"
          }}
        >
          <Autocomplete
            id="station-find"
            options={stationOptions}
            sx={{ width: "100%" }}
            // filterOptions={(x) => x}
            loading={loader}
            value={selectedStationOption}
            onChange={(e, newValue) => {
              setSelectedStationOption(newValue);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loader ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  label="Find charger by id"
                />
              );
            }}
          />
        </Box>

        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="success"
          disabled={!selectedStationOption}
          onClick={handleContinueClick}
        >
          <i className="ic-continue" /> Continue
        </Button>
      </CardContent>
    </Card>
  );
};
