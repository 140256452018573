import { useContext } from "react";

import { ServiceContainer, ServiceContainerContext } from "../providers/ServiceContainerProvider";

export const useServiceContainer = (): ServiceContainer => {
  const serviceContainer = useContext(ServiceContainerContext);

  if (!serviceContainer) {
    throw new Error("useServiceContainer must be used within a ServiceContainerProvider");
  }

  return serviceContainer;
};
