import { useCallback, useState } from "react";

import { debounce } from "lodash";

import { IScopedStation } from "../common/interfaces/station.interface";
import { useServiceContainer } from "./useServiceContainer";

export const useStationList = () => {
  const [stationList, setStationList] = useState<IScopedStation[]>([]);
  const [loader, setLoader] = useState(false);
  const { stationService } = useServiceContainer();

  const loadStationList = useCallback(async () => {
    try {
      setLoader(true);
      const res = await stationService.getStationList();

      setStationList(res);
    } catch (e) {
      // todo: toasts??
      console.error(e);
    } finally {
      setLoader(false);
    }
  }, []);

  const debouncedLoadStationList = useCallback(debounce(loadStationList, 500), []);

  const resetStationList = useCallback(() => {
    setStationList([]);
  }, []);

  return {
    stationList,
    loader,
    debouncedLoadStationList,
    resetStationList,
    loadStationList
  };
};
