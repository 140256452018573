export interface IStation {
  station_id: string;
  station_name: string;
  station_uuid: string;
  description: string;
  kwh_price: string;
  minute_price: string;
  session_price: string;
  currency: string;
  minimal_price: string;
  connectors: IStationConnector[];
}

export type IScopedStation = Pick<IStation, "station_id" | "station_name" | "station_uuid">;

export interface IStationConnector {
  connector_id: number;
  connector_uuid: string;
  power: string;
  state: StationConnectorState;
  type: string;
  mode: string;
}

export type IScopedStationConnector = Pick<IStationConnector, "state" | "connector_uuid">;

export enum StationConnectorState {
  AVAILABLE = "AVAILABLE",
  PREPARING = "PREPARING",
  CHARGING = "CHARGING",
  SUSPENDEDEVSE = "SUSPENDEDEVSE",
  SUSPENDEDEV = "SUSPENDEDEV",
  FINISHING = "FINISHING",
  RESERVED = "RESERVED",
  UNAVAILABLE = "UNAVAILABLE",
  DISABLED = "DISABLED",
  FAULTED = "FAULTED",
  FAULTY = "FAULTY",
  UNKNOWN = "UNKNOWN"
}
