import { STORAGE_KEYS } from "../common/constants/storage-values.constant";

export class TokenService {
  static setAccessToken(token: string) {
    window.localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, token);
  }

  static getAccessToken() {
    return window.localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);
  }

  static removeAccessToken() {
    window.localStorage.removeItem(STORAGE_KEYS.AUTH_TOKEN);
  }
}
