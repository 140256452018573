import React from "react";

import { Box, Grid } from "@mui/material";

import logoSrc from "../../../common/assets/images/logo.png";
import { HEADER_HEIGHT } from "../../../common/constants/layout.constant";
import { PartnerLogoBar } from "../PartnerLogoBar";

export const Header = () => {
  return (
    <Box
      sx={{
        height: `${HEADER_HEIGHT}px`,
        display: "flex",
        alignItems: "center",
        px: {
          xs: 1,
          sm: 2,
          md: 3
        }
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Box
            sx={{
              maxHeight: "80px",
              "& img": {
                maxHeight: "80px"
              }
            }}
          >
            <img src={logoSrc} alt="Logo" />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: {
              xs: "none",
              md: "flex"
            },
            justifyContent: "flex-end"
          }}
        >
          <PartnerLogoBar />
        </Grid>
      </Grid>
    </Box>
  );
};
