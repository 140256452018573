import React, { useMemo } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Tooltip } from "@mui/material";

// import { ReactComponent as DefaultSocketIcon } from "../../common/assets/icons/sockets/default-socket-icon.svg";
import { IStationConnector, StationConnectorState } from "../../common/interfaces/station.interface";

interface Props {
  connector: IStationConnector;
  isSelected: boolean;
  onSelect: () => void;
}

export const Connector: React.FC<Props> = ({ connector, isSelected, onSelect }) => {
  const canSelect = useMemo(() => {
    return connector.state === StationConnectorState.PREPARING || connector.state === StationConnectorState.FINISHING;
  }, [connector.state]);

  const statusColor = useMemo(() => {
    switch (connector.state) {
      case StationConnectorState.AVAILABLE:
        return "success.light";
      case StationConnectorState.PREPARING:
      case StationConnectorState.FINISHING:
        return "primary.light";
      case StationConnectorState.CHARGING:
        return "warning.light";
      case StationConnectorState.SUSPENDEDEVSE:
      case StationConnectorState.SUSPENDEDEV:
      case StationConnectorState.RESERVED:
      case StationConnectorState.UNAVAILABLE:
      case StationConnectorState.DISABLED:
      case StationConnectorState.FAULTED:
      case StationConnectorState.FAULTY:
      case StationConnectorState.UNKNOWN:
      default:
        return "error.light";
    }
  }, [connector.state]);

  const statusText = useMemo(() => {
    switch (connector.state) {
      case StationConnectorState.AVAILABLE:
        return "Connector is available, please plug in connector into the vehicle";
      case StationConnectorState.PREPARING:
        return "Connector is plugged in, please select the connector to start charging";
      case StationConnectorState.CHARGING:
        return "Connector is already charging";
      case StationConnectorState.SUSPENDEDEVSE:
      case StationConnectorState.SUSPENDEDEV:
      case StationConnectorState.FINISHING:
      case StationConnectorState.RESERVED:
      case StationConnectorState.UNAVAILABLE:
      case StationConnectorState.DISABLED:
      case StationConnectorState.FAULTED:
      case StationConnectorState.FAULTY:
      case StationConnectorState.UNKNOWN:
      default:
        return `Connector is not available: ${connector.state}`;
    }
  }, [connector.state]);

  return (
    <Tooltip title={statusText}>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: statusColor,
          color: "#fff",
          p: 1,
          borderRadius: "1rem",
          m: 0.5,
          ...(!canSelect && {
            opacity: 0.3
          }),
          borderWidth: "2px",
          borderStyle: "solid",
          cursor: canSelect ? "pointer" : "default",
          "&:hover": {
            ...(canSelect && {
              opacity: 0.8
            })
          },
          ...(isSelected && {
            opacity: 1,
            borderColor: "#000"
          })
        }}
        onClick={() => {
          if (canSelect) {
            onSelect();
          }
        }}
      >
        {isSelected && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: "5px",
              color: "#00ff13"
            }}
          >
            <CheckCircleIcon
              sx={{
                fontSize: 15
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            "& svg": {
              width: "2rem"
            }
          }}
        >
          <i className="ic-default-socket" />
          {/* <img src= alt=""/> */}
        </Box>

        <Box
          sx={{
            fontWeight: "600",
            fontSize: 12
          }}
        >
          {connector.power ?? "2"} KW
        </Box>
      </Box>
    </Tooltip>
  );
};
