import React from "react";

import { Router } from "./components/router/Router";

const AppInner = () => {
  return (
    <>
      <Router />
    </>
  );
};

export default AppInner;
