import enFlag from "../assets/images/flags/en.png";

const languages = [
  // {
  //   label: "Русский",
  //   image: ruFlag,
  //   key: "ru"
  // },
  {
    label: "English",
    image: enFlag,
    key: "en"
  }
];

export { languages };
