import React from "react";

import { Box, Typography } from "@mui/material";

import { ReactComponent as ChargingStationIcon } from "../../common/assets/icons/charging-station.svg";
import { KWH_PRICE } from "../../common/constants/connector.constant";
import { IStation, IStationConnector } from "../../common/interfaces/station.interface";
import { Connector } from "./Connector";

interface Props {
  station: IStation;
  connectors: IStationConnector[];
  selectedConnector: IStationConnector | null;
  onConnectorSelect: (connector: IStationConnector) => void;
}

export const StationConnector: React.FC<Props> = ({ selectedConnector, station, connectors, onConnectorSelect }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%"
      }}
    >
      <Box
        sx={{
          "& svg": {
            width: "2rem"
          }
        }}
      >
        <ChargingStationIcon />
      </Box>
      <Box
        sx={{
          ml: 2
        }}
      >
        <Typography variant="subtitle1">
          kWh: {station.kwh_price ?? 0.0} {station.currency}
        </Typography>
        <Typography variant="subtitle1">
          Minute: {station.minute_price ?? 0.0} {station.currency}
        </Typography>
        <Typography variant="subtitle1">
          Session: {station.session_price ?? 0.0} {station.currency}
        </Typography>
        <Typography variant="subtitle1" />
        <Typography variant="subtitle2" color="red">
          {!station.session_price && !station.kwh_price && !station.minute_price
            ? `Card will be charged for ${station.minimal_price} ${station.currency}`
            : ""}
        </Typography>
      </Box>
      <Box
        sx={{
          ml: "auto",
          display: "flex"
        }}
      >
        {connectors.map((connector, index) => (
          <Connector
            key={index}
            isSelected={selectedConnector?.connector_uuid === connector.connector_uuid}
            connector={connector}
            onSelect={() => onConnectorSelect(connector)}
          />
        ))}
      </Box>
    </Box>
  );
};
