import { IBaseApiResponse } from "../../../common/interfaces/api.interface";
import { IScopedStation, IStation } from "../../../common/interfaces/station.interface";
import BaseAPIService from "../BaseApiService";

class StationApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/simplecharge/station`);
  }

  public async getStationById(id: string): Promise<IStation> {
    const res = await this.api.get<IBaseApiResponse<IStation>>(`/${id}`, {
      headers: {
        Authorization: ""
      }
    });

    return res.data.data;
  }

  public async getStationList(): Promise<IScopedStation[]> {
    const res = await this.api.get<IBaseApiResponse<IScopedStation[]>>(`/`, {
      headers: {
        Authorization: ""
      }
    });

    return res.data.data;
  }
}

const instance = new StationApiService();

export default instance;
