import React, { useState } from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { IStation, IStationConnector } from "../../common/interfaces/station.interface";
import { StationConnector } from "./StationConnector";

interface Props {
  station: IStation;
}

export const StationDetailsForm: React.FC<Props> = ({ station }) => {
  const [selectedConnector, setSelectedConnector] = useState<IStationConnector | null>(null);

  const navigate = useNavigate();

  const handleContinueClick = () => {
    navigate(`/checkout/${selectedConnector!.connector_uuid}`);
  };

  const handleReturnBack = () => {
    navigate("/");
  };

  return (
    <Card sx={{ minHeight: 270, background: "#fcfcfc" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%"
        }}
      >
        <Typography variant="h4">{station.station_id}</Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            color: "success.main"
          }}
        >
          {station.description ?? "Opened 24/7"}
        </Typography>
        <Box
          sx={{
            w: "100%",
            mt: 2
          }}
        >
          <StationConnector
            station={station}
            connectors={station.connectors}
            selectedConnector={selectedConnector}
            onConnectorSelect={(connector) => {
              setSelectedConnector(connector);
            }}
          />
        </Box>
        <Box
          sx={{
            w: "100%",
            mt: 2,
            display: "flex"
          }}
        >
          <Button sx={{ mt: 2 }} variant="text" color="primary" onClick={handleReturnBack}>
            <ChevronLeftIcon /> Return back
          </Button>
          <Button
            sx={{ mt: 2, ml: "auto" }}
            variant="contained"
            color="success"
            disabled={!selectedConnector}
            onClick={handleContinueClick}
          >
            <i className="ic-continue" /> Continue
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
