import React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ConfirmProvider } from "material-ui-confirm";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AppInner from "./AppInner";
import { ServiceContainerProvider } from "./providers/ServiceContainerProvider";

const theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#eee"
    }
  }
});

function App() {
  return (
    <BrowserRouter>
      <ServiceContainerProvider>
        <ConfirmProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppInner />
            <ToastContainer />
          </ThemeProvider>
        </ConfirmProvider>
      </ServiceContainerProvider>
    </BrowserRouter>
  );
}

export default App;
