import React from "react";

export const useInterval = (callback: () => any, delay: number) => {
  const intervalRef = React.useRef<any>(null);
  const savedCallback = React.useRef<() => any>(callback);

  const start = () => {
    const tick = () => savedCallback.current();
    intervalRef.current = window.setInterval(tick, delay);
  };

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (typeof delay === "number") {
      start();
      return () => window.clearInterval(intervalRef.current);
    }

    return () => window.clearInterval(intervalRef.current);
  }, [delay]);
  return {
    intervalRef,
    resumeInterval: start,
    stopInterval: () => window.clearInterval(intervalRef.current)
  };
};
