import React from "react";

import { IndexPage, StationDetailsPage } from "../../pages";
import { CheckoutPage } from "../../pages/CheckoutPage";
import { ConnectorSessionPage } from "../../pages/ConnectorSessionPage";

export interface AppRoute {
  path: string;
  private?: boolean;
  exact?: boolean;
  component: any;
}

export const routes: AppRoute[] = [
  {
    path: "/",
    component: <IndexPage />,
    private: false,
    exact: true
  },
  {
    path: "/station/:id",
    component: <StationDetailsPage />,
    private: false,
    exact: true
  },
  {
    path: "/session/:id",
    component: <ConnectorSessionPage />,
    private: false,
    exact: true
  },
  {
    path: "/checkout/:connectorId",
    component: <CheckoutPage />,
    private: false,
    exact: true
  }
];
