import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import localization from "../../common/i18n/config";
import CookiesService from "../../services/CookiesService";
import type { RootState } from "../store";

interface AppState {
  pageLoader: boolean;
  language: string;
}

const initialState: AppState = {
  pageLoader: true,
  language: CookiesService.get("lng") || localization.language || "en"
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setPageLoader: (state: AppState, action: PayloadAction<boolean>) => {
      state.pageLoader = action.payload;
    },
    setSelectedLanguage: (state: AppState, action: PayloadAction<string>) => {
      state.language = action.payload;
    }
  }
});

export const { setPageLoader, setSelectedLanguage } = appSlice.actions;

export const selectSelectedLanguage = (state: RootState) => state.app.language;
export const selectPageLoader = (state: RootState) => state.app.pageLoader;

export default appSlice.reducer;
