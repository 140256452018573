import React from "react";

import { BasePage } from "../components/Layout/BasePage";
import { FindStationForm } from "../components/station/FindStationForm";

export const IndexPage = () => {
  return (
    <BasePage>
      <FindStationForm />
    </BasePage>
  );
};
