import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import { IStation } from "../common/interfaces/station.interface";
import { useServiceContainer } from "./useServiceContainer";

export const useStation = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const [station, setStation] = useState<IStation | null>(null);

  const { stationService, ctekConnectorService } = useServiceContainer();

  const loadStation = useCallback(async (stationId: string) => {
    setLoader(true);
    try {
      const stationRes = await stationService.getStationById(stationId);
      setStation(stationRes);

      await updateConnectorStates(stationRes);
    } catch (e) {
      console.error(e);
      navigate("/");
    } finally {
      setLoader(false);
    }
  }, []);

  const updateConnectorStates = useCallback(
    async (stationRes?: IStation) => {
      const stationTarget = stationRes ?? station ?? null;

      if (!stationTarget) return;
      try {
        const states = await ctekConnectorService.getConnectorStatesByIds(
          stationTarget.connectors.map((c) => c.connector_uuid)
        );

        const stationCopy = { ...stationTarget };

        for (const connector of stationCopy.connectors) {
          const target = states.find((s) => s.connector_uuid === connector.connector_uuid);
          if (target) {
            connector.state = target.state;
          }
        }

        setStation(stationCopy);
      } catch (e) {
        console.error(e);
      }
    },
    [station]
  );

  return {
    station,
    loader,
    loadStation,
    updateConnectorStates
  };
};
