import React, { useMemo } from "react";

import { Box, Button, Chip, Link, Typography } from "@mui/material";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

import { KWH_PRICE } from "../../common/constants/connector.constant";
import { STORAGE_KEYS } from "../../common/constants/storage-values.constant";
import { ISessionSummary } from "../../common/interfaces/connector.interface";
import LocalStorageService from "../../services/LocalStorageService";
import { DataTable } from "../common/DataTable";

interface Props {
  summaryData: ISessionSummary;
}

export const SessionSummaryForm: React.FC<Props> = ({ summaryData }) => {
  const navigate = useNavigate();
  const indicators = useMemo(() => {
    return [
      {
        label: (
          <>
            <i className="ic-time" />
            Session time
          </>
        ),
        value: (
          <Moment interval={1000} duration={summaryData.session_start} format="HH:mm:ss">
            {summaryData.session_end}
          </Moment>
        )
      },
      {
        label: (
          <>
            <i className="ic-charge" />
            Accumulated energy
          </>
        ),
        value: `${summaryData.accumulated_energy} kWh`
      },
      {
        label: (
          <>
            <i className="ic-banknote" />
            Price per session
          </>
        ),
        value: `${summaryData.session_price} ${summaryData.currency}`
      },
      {
        label: (
          <>
            <i className="ic-banknote" />
            Price per minute
          </>
        ),
        value: `${summaryData.minute_price} ${summaryData.currency}`
      },
      {
        label: (
          <>
            <i className="ic-banknote" />
            Price per KW
          </>
        ),
        value: `${summaryData.kwh_price} ${summaryData.currency}`
      },
      {
        label: (
          <>
            <i className="ic-banknote" />
            Minimal cost
          </>
        ),
        value: `${summaryData.minimal_price} ${summaryData.currency}`
      },
      {
        label: (
          <>
            <i className="ic-card-hand" />
            Total cost
          </>
        ),
        value: <Chip color="info" label={`${summaryData.amount_charged} ${summaryData.currency}`} />
      },
      {
        label: (
          <>
            <i className="ic-mail" />
            Receipt
          </>
        ),
        value: (
          <>
            <Link href={summaryData.invoice_url} target="_blank">
              View receipt
            </Link>
          </>
        )
      }
    ];
  }, [summaryData]);

  const handleNewChargeSessionClick = () => {
    LocalStorageService.remove(STORAGE_KEYS.SESSION_ID);
    navigate("/");
  };

  return (
    <>
      <Typography variant="h4">{summaryData.station_name}</Typography>
      {/* <Typography variant="h6">{connectorSession.station_id}</Typography> */}
      <Box
        sx={{
          w: "100%",
          mt: 2
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mb: 2
          }}
        >
          Session is finished, thank you for using our service!
        </Typography>
        <DataTable items={indicators} />
      </Box>
      <Box
        sx={{
          w: "100%",
          mt: 2,
          display: "flex"
        }}
      >
        <Button sx={{ mt: 1, ml: "auto" }} variant="contained" color="success" onClick={handleNewChargeSessionClick}>
          <i className="ic-refresh" /> Start new charge session
        </Button>
      </Box>
    </>
  );
};
