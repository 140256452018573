export interface CountedItems<T> {
  items: T[];
  count: number;
}

export interface IBaseApiResponse<T> {
  message: string;
  data: T;
  code: ApiErrorCode;
}

export enum ApiErrorCode {
  SessionAborted = 499,
  TransactionInvalid = 498,
  Ok = 200,
  InternalServerError = 500
}
