import React from "react";

import { Box, CircularProgress } from "@mui/material";

interface Props {
  fullBlind?: boolean;
  parentBlind?: boolean;
  children?: any;
}

export const Loader: React.FC<Props> = ({ fullBlind, parentBlind, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        ...((fullBlind || parentBlind) && {
          position: fullBlind ? "fixed" : "absolute",
          top: 0,
          left: 0,
          zIndex: 9999,
          background: "#fff"
        })
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress />

        <Box
          sx={{
            mt: 2
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
