import React, { useEffect } from "react";

import { Card, CardContent, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { useNavigate, useParams } from "react-router-dom";

import heroImageSrc from "../common/assets/images/hero/sc_2.png";
import { SessionStatus } from "../common/interfaces/connector.interface";
import { Loader } from "../components/common/Loader";
import { ConnectorSessionForm } from "../components/connector/ConnectorSessionForm";
import { SessionSummaryForm } from "../components/connector/SessionSummaryForm";
import { BasePage } from "../components/Layout/BasePage";
import { useConnectorSession } from "../hooks/useConnectorSession";

export const ConnectorSessionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    connectorSession,
    sessionSummaryData,
    loadConnectorSession,
    stopSession,
    stopLoader,
    loader: sessionLoader
  } = useConnectorSession(id!);

  const confirm = useConfirm();

  const handleSessionStop = () => {
    confirm({ description: "Do you really want to stop charging session?" })
      .then(() => {
        stopSession();
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!id) {
      navigate("/");
    } else {
      loadConnectorSession(id).then(() => null);
    }
  }, [id]);

  return (
    <BasePage imageSrc={heroImageSrc} hideImageOnMobile>
      {sessionLoader && (
        <>
          <Loader fullBlind />
        </>
      )}

      <Card sx={{ minHeight: 300, display: "flex", background: "#fcfcfc" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%"
            // height: "100%"
          }}
        >
          {connectorSession && !sessionSummaryData && !stopLoader ? (
            connectorSession.session && connectorSession.transaction.ctek_status !== SessionStatus.Preparing ? (
              <ConnectorSessionForm
                onSessionStop={handleSessionStop}
                transaction={connectorSession.transaction}
                connectorSession={connectorSession.session}
              />
            ) : (
              <>
                <Loader>
                  <Typography variant="h5">Preparing session...</Typography>
                </Loader>
              </>
            )
          ) : (
            ""
          )}

          {stopLoader && (
            <>
              <Loader>
                <Typography variant="h5">Stopping session...</Typography>
              </Loader>
            </>
          )}

          {sessionSummaryData && <SessionSummaryForm summaryData={sessionSummaryData} />}
        </CardContent>
      </Card>
    </BasePage>
  );
};
