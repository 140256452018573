import React from "react";

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./styles/style.scss";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import localization from "./common/i18n/config";
import reportWebVitals from "./reportWebVitals";
import CookiesService from "./services/CookiesService";
import { setSelectedLanguage } from "./store/app/appSlice";
import { store } from "./store/store";

const initLang = () => {
  if (CookiesService.get("lng")) {
    localization.changeLanguage(CookiesService.get("lng")).then(() => null);
    store.dispatch(setSelectedLanguage(CookiesService.get("lng")));
  }
};

initLang();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
