import React, { useState } from "react";

import { Alert, Box, Button } from "@mui/material";
import { useStripe, useElements, PaymentElement, LinkAuthenticationElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

import { useServiceContainer } from "../../hooks/useServiceContainer";
import { Loader } from "../common/Loader";

interface Props {
  trxId: string;
  stationId: string;
  amountToCapture: string;
  minimalPrice: string;
  currency: string;
}

const CheckoutForm: React.FC<Props> = ({ trxId, stationId, amountToCapture, minimalPrice, currency }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loader, setLoader] = useState(false);
  const { sessionService } = useServiceContainer();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoader(true);

    const result = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_APP_URL}/session/${trxId}`
      }
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      toast.error(result.error.message);

      setLoader(false);
    } else {
      console.log("result", result);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const handleCancel = () => {
    setLoader(true);
    sessionService.cancelTransactionById(trxId, `${process.env.REACT_APP_BASE_APP_URL}/station/${stationId}`);
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      {loader && <Loader parentBlind />}
      <LinkAuthenticationElement className="mb-2" />

      <Box
        sx={{
          mt: 2
        }}
      >
        <PaymentElement />
      </Box>
      <Alert
        severity="info"
        sx={{
          mt: 2
        }}
      >
        Please note that the minimal price for a charging session is{" "}
        <strong>
          {minimalPrice} {currency}
        </strong>
        . <br /> To start charging, we reserve{" "}
        <strong>
          {parseInt(amountToCapture, 10) / 100} {currency}
        </strong>{" "}
        from you account, which will be released after charging..
      </Alert>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          width: "100%"
        }}
      >
        <Button type="button" variant="contained" color="error" onClick={handleCancel}>
          <i className="ic-cancel" /> Cancel
        </Button>
        <Button type="submit" variant="contained" color="success" disabled={!stripe || loader}>
          <i className="ic-continue" /> Continue
        </Button>
      </Box>
    </form>
  );
};

export default CheckoutForm;
