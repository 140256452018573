import React from "react";

import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";

import { HEADER_HEIGHT } from "../../common/constants/layout.constant";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";

export const Layout = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: `calc(100% - ${HEADER_HEIGHT}px)`,
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // height: "100%",
          flexGrow: 1
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Container>
  );
};
