import React, { createContext } from "react";

import CtekConnectorApiService from "../services/api/ctek/CtekConnectorApiService";
import SessionApiService from "../services/api/simplecharge/SessionApiService";
import SiteApiService from "../services/api/simplecharge/SiteApiService";
import StationApiService from "../services/api/simplecharge/StationApiService";

export type ServiceContainer = {
  stationService: typeof StationApiService;
  siteService: typeof SiteApiService;
  ctekConnectorService: typeof CtekConnectorApiService;
  sessionService: typeof SessionApiService;
};

export const ServiceContainerContext = createContext<ServiceContainer | null>(null);

type ServiceContainerProviderProps = {
  children: React.ReactNode;
};

export const ServiceContainerProvider: React.FC<ServiceContainerProviderProps> = ({ children }) => {
  const serviceContainer: ServiceContainer = {
    stationService: StationApiService,
    siteService: SiteApiService,
    ctekConnectorService: CtekConnectorApiService,
    sessionService: SessionApiService
  };

  return <ServiceContainerContext.Provider value={serviceContainer}>{children}</ServiceContainerContext.Provider>;
};
