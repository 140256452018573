import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

export interface DataTableItem {
  label: any;
  value: any;
}

interface Props {
  items: DataTableItem[];
}

export const DataTable: React.FC<Props> = ({ items }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="indicators">
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& i": {
                      marginRight: "10px"
                    }
                  }}
                >
                  {item.label}
                </Box>
              </TableCell>
              <TableCell align="left">{item.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
