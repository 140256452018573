import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from "i18next-intervalplural-postprocessor";
import { initReactI18next } from "react-i18next";

import { languages } from "../constants/language.constant";
import globalEn from "./dictionary/en/global.json";

const options = {
  order: ["querystring"],
  lookupQuerystring: "lng"
};

export const resources = {
  en: {
    global: globalEn
  }
} as const;

i18n
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    lng: "en",
    detection: options,
    supportedLngs: languages.map((e) => e.key),
    ns: ["global"],
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    resources
  });

export default i18n;
