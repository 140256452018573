import React, { useMemo } from "react";

import { Box, Button, Chip, Typography } from "@mui/material";
import Moment from "react-moment";

import { ICtekConnectorSession, ITransaction } from "../../common/interfaces/connector.interface";
import { getApproximateCost } from "../../common/utils/price.util";
import { DataTable } from "../common/DataTable";

interface Props {
  connectorSession: ICtekConnectorSession;
  transaction: ITransaction;
  onSessionStop: () => void;
}

export const ConnectorSessionForm: React.FC<Props> = ({ onSessionStop, transaction, connectorSession }) => {
  const indicators = useMemo(() => {
    return [
      {
        label: (
          <>
            <i className="ic-time" />
            Active time
          </>
        ),
        value: (
          <Moment interval={1000} durationFromNow format="HH:mm:ss">
            {transaction.session_start}
          </Moment>
        )
      },
      {
        label: (
          <>
            <i className="ic-charge" />
            Accumulated energy
          </>
        ),
        value: `${connectorSession.accumulated_energy} kWh`
      },
      {
        label: (
          <>
            <i className="ic-charger" />
            Power
          </>
        ),
        value: `${connectorSession.power} kW`
      },
      {
        label: (
          <>
            <i className="ic-banknote" />
            Price per session
          </>
        ),
        value: `${transaction.session_price.toFixed(2) ?? "0.00"} ${transaction.currency}`
      },
      {
        label: (
          <>
            <i className="ic-banknote" />
            Price per minute
          </>
        ),
        value: `${transaction.minute_price.toFixed(2) ?? "0.00"} ${transaction.currency}`
      },
      {
        label: (
          <>
            <i className="ic-banknote" />
            Price per KW
          </>
        ),
        value: `${transaction.kwh_price.toFixed(2) ?? "0.00"} ${transaction.currency}`
      },
      {
        label: (
          <>
            <i className="ic-banknote" />
            Minimal cost
          </>
        ),
        value: `${transaction.minimal_price.toFixed(2)} ${transaction.currency}`
      },
      {
        label: (
          <>
            <i className="ic-card-hand" />
            Approximate cost
          </>
        ),
        value: (
          <Chip
            color="info"
            label={`${getApproximateCost(
              connectorSession.accumulated_energy,
              transaction.session_start,
              transaction.session_price,
              transaction.minute_price,
              transaction.kwh_price,
              transaction.minimal_price
            )} ${transaction.currency}`}
          />
        )
      }
    ];
  }, [
    transaction.session_start,
    connectorSession.accumulated_energy,
    connectorSession.power,
    transaction.session_price,
    transaction.minute_price,
    transaction.kwh_price,
    transaction.minimal_price
  ]);

  return (
    <>
      <Typography variant="h4">{connectorSession.station_name}</Typography>
      <Typography variant="h6">{connectorSession.station_id}</Typography>
      <Box
        sx={{
          w: "100%",
          mt: 2
        }}
      >
        <DataTable items={indicators} />
      </Box>
      <Box
        sx={{
          w: "100%",
          mt: 2,
          display: "flex"
        }}
      >
        <Button sx={{ mt: 1, ml: "auto" }} variant="contained" color="error" onClick={onSessionStop}>
          <i className="ic-stop" /> Stop
        </Button>
      </Box>
    </>
  );
};
