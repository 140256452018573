class LocalStorageService {
  instance: Storage;

  constructor() {
    this.instance = window.localStorage;
  }

  set(key: string, value: any) {
    this.instance.setItem(key, value);
  }

  get(key: string) {
    return this.instance.getItem(key);
  }

  remove(key: string) {
    this.instance.removeItem(key);
  }
}

const instance = new LocalStorageService();

export default instance;
