import React from "react";

import { Box, Grid } from "@mui/material";

import heroImageSrc from "../../common/assets/images/hero/sc_1.png";

interface Props {
  children: any;
  imageSrc?: string;
  hideImageOnMobile?: boolean;
}

export const BasePage: React.FC<Props> = ({ children, imageSrc, hideImageOnMobile }) => {
  return (
    <Box
      sx={{
        height: "100%"
      }}
    >
      <Grid
        container
        sx={{
          alignItems: "center"
        }}
      >
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: {
              xs: hideImageOnMobile ? "none" : "block",
              md: "block"
            },
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              px: {
                xs: 1,
                sm: 2,
                md: 3
              },
              "& img": {
                width: "100%",
                // maxWidth: "400px",
                objectFit: "contain",
                maxHeight: {
                  xs: "250px",
                  md: "400px"
                }
              },
              display: "flex",
              justifyContent: "center"
            }}
          >
            <img src={imageSrc ?? heroImageSrc} alt="Charger search" />
          </Box>
        </Grid>

        <Grid item md={6} xs={12}>
          <Box
            sx={{
              px: {
                xs: 1,
                sm: 2,
                md: 3
              }
            }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
