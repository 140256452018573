import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Card, CardContent } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate, useParams } from "react-router-dom";

import heroImageSrc from "../common/assets/images/hero/sc_3.png";
import CheckoutForm from "../components/checkout/CheckoutForm";
import { Loader } from "../components/common/Loader";
import { BasePage } from "../components/Layout/BasePage";
import { useServiceContainer } from "../hooks/useServiceContainer";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY as string);

export const CheckoutPage = () => {
  const { connectorId } = useParams();
  const { sessionService } = useServiceContainer();
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState("");
  const [trxId, setTrxId] = useState("");
  const [stationId, setStationId] = useState("");
  const [amountToCapture, setAmountToCapture] = useState("");
  const [minimalPrice, setMinimalPrice] = useState("");
  const [currency, setCurrency] = useState("");

  const options = useMemo(() => {
    return {
      clientSecret
    };
  }, [clientSecret]);

  const prepareSession = useCallback(async (_connectorId: string) => {
    try {
      if (!connectorId) return;
      const session = await sessionService.prepareConnectorSession(_connectorId);

      setClientSecret(session.stripe_secret);
      setTrxId(session.transaction_uuid);
      setStationId(session.station_uuid);
      setAmountToCapture(session.amount_to_capture);
      setMinimalPrice(session.minimal_price);
      setCurrency(session.currency);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (!connectorId) {
      navigate("/");
      return;
    }

    prepareSession(connectorId).then(() => null);
  }, [connectorId]);

  if (!clientSecret) {
    return <Loader fullBlind />;
  }

  return (
    <BasePage imageSrc={heroImageSrc}>
      <Card
        sx={{
          position: "relative"
        }}
      >
        <CardContent>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              trxId={trxId}
              stationId={stationId}
              amountToCapture={amountToCapture}
              minimalPrice={minimalPrice}
              currency={currency}
            />
          </Elements>
        </CardContent>
      </Card>
    </BasePage>
  );
};
