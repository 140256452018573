import React from "react";

import { Grid } from "@mui/material";

import { PartnerLogoBar } from "../PartnerLogoBar";

export const Footer = () => {
  return (
    <Grid
      container
      sx={{
        my: 2,
        px: {
          xs: 1,
          sm: 2,
          md: 3
        }
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: {
            xs: "flex",
            md: "none"
          },
          "& img": {
            xs: {
              width: "100%",
              maxHeight: "50px"
            }
          },
          "> div": {
            width: "100%",
            justifyContent: "space-between"
          }
        }}
      >
        <PartnerLogoBar />
      </Grid>
    </Grid>
  );
};
