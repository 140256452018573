import React, { useCallback, useEffect, useMemo } from "react";

import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { STORAGE_KEYS } from "../../common/constants/storage-values.constant";
import LocalStorageService from "../../services/LocalStorageService";
import { Layout } from "../Layout/Layout";
import { AppRoute, routes } from "./routes";

export const Router: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const assembleRoutes = useCallback((layout: AppRoute[]) => {
    return layout.map((e, idx) => <Route key={idx} path={e.path} element={e.component} />);
  }, []);

  const indexRoutes = useMemo(() => assembleRoutes(routes), []);

  useEffect(() => {
    const savedSession = LocalStorageService.get(STORAGE_KEYS.SESSION_ID);
    if (savedSession) {
      navigate(`/session/${savedSession}`);
    }
  }, []);

  return (
    <>
      <Routes key={location.pathname} location={location}>
        <Route path="*" element={<Navigate replace to="/404" />} />
        <Route path="/" element={<Layout />}>
          {indexRoutes}
        </Route>
      </Routes>
    </>
  );
};
